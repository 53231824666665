import Amplify, { Auth } from "aws-amplify";

// Funzione per gestire il logout
const logout = async () => {
  try {
    await Auth.signOut();
    window.location.href = process.env.REACT_APP_REDIRECT_AFTER_LOGOUT || '/login';
  } catch (error) {
    console.error("Errore durante il logout:", error);
  }
};

// Funzione per verificare e rinfrescare la sessione, e logout se necessario
const checkAndRefreshSession = async () => {
  try {
    const session = await Auth.currentSession();
    
    // Se il token è valido, restituisci la sessione corrente
    return session;

  } catch (error) {
    console.error("Errore durante il check/refresh del token:", error);
    await logout();
  }
};

const getCustomAuthHeader = async () => {
  const session = await checkAndRefreshSession();
  return {
    Authorization: "Bearer " + session.idToken.jwtToken,
  };
};

const oauth = {
  // Domain name
  domain: process.env.REACT_APP_DOMAIN_NAME,

  // Authorized scopes
  scope: [
    "phone",
    "email",
    "profile",
    "openid",
    "aws.cognito.signin.user.admin",
    "https://www.googleapis.com/auth/drive",
    "https://www.googleapis.com/auth/spreadsheets",
  ],

  // Callback URL
  //redirectSignIn: "https://d2dehxbiltskbc.cloudfront.net/google",
  redirectSignIn: process.env.REACT_APP_RED_SIGN_IN,
  // Sign out URL
  //redirectSignOut: "https://d2dehxbiltskbc.cloudfront.net/login",
  redirectSignOut: process.env.REACT_APP_RED_SIGN_OUT,

  // 'code' for Authorization code grant,
  // 'token' for Implicit grant
  responseType: "code",

  // optional, for Cognito hosted ui specified options
  // options: {
  //   // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
  //   AdvancedSecurityDataCollectionFlag: true
  // }
};

const amplifyConfig = Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL,
    region: process.env.REACT_APP_BASE_REGION,
    userPoolId: process.env.REACT_APP_WEB_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
    oauth: oauth,
  },
  API: {
    endpoints: [

      {
        name: "expenseTypes",
        region: process.env.REACT_APP_BASE_REGION,
        endpoint: process.env.REACT_APP_BASEURL_EXPENSE + "expenseTypes",
        custom_header: getCustomAuthHeader
      },
      {
        name: "currency",
        region: process.env.REACT_APP_BASE_REGION,
        endpoint: process.env.REACT_APP_BASEURL_CURRENCY + "currencies",
        custom_header: getCustomAuthHeader,
      },
      {
        name: "hourTypes",
        region: process.env.REACT_APP_BASE_REGION,
        endpoint: process.env.REACT_APP_BASEURL_HOUR + "hourTypes",
        custom_header: getCustomAuthHeader,
      },
      {
        name: "company",
        region: process.env.REACT_APP_BASE_REGION,
        endpoint: process.env.REACT_APP_BASEURL_COMPANY + "companies",
        custom_header: getCustomAuthHeader,
      },
      {
        name: "country",
        region: process.env.REACT_APP_BASE_REGION,
        endpoint: process.env.REACT_APP_BASEURL_COMPANY + "countries",
        custom_header: getCustomAuthHeader,
      },
      {
        name: "email_signature_offices",
        region: process.env.REACT_APP_BASE_REGION,
        endpoint: process.env.REACT_APP_BASEURL_COMPANY,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "practice",
        region: process.env.REACT_APP_BASE_REGION,
        endpoint: process.env.REACT_APP_BASEURL_COMPANY + "practices",
        custom_header: getCustomAuthHeader,
      },
      {
        name: "holiday",
        region: process.env.REACT_APP_BASE_REGION,
        endpoint: process.env.REACT_APP_BASEURL_COMPANY + "calendars",
        custom_header: getCustomAuthHeader,
      },
      {
        name: "invoice_type",
        region: process.env.REACT_APP_BASE_REGION,
        endpoint: process.env.REACT_APP_BASEURL_TYPE + "invoiceType",
        custom_header: getCustomAuthHeader,
      },
      {
        name: "activity_type",
        region: process.env.REACT_APP_BASE_REGION,
        endpoint: process.env.REACT_APP_BASEURL_TYPE + "activityType",
        custom_header: getCustomAuthHeader,
      },
      {
        name: "product_type",
        region: process.env.REACT_APP_BASE_REGION,
        endpoint: process.env.REACT_APP_BASEURL_TYPE + "productType",
        custom_header: getCustomAuthHeader,
      },
      {
        name: "project_type",
        region: process.env.REACT_APP_BASE_REGION,
        endpoint: process.env.REACT_APP_BASEURL_TYPE + "projectType",
        custom_header: getCustomAuthHeader,
      },
      {
        name: "offering_type",
        region: process.env.REACT_APP_BASE_REGION,
        endpoint: process.env.REACT_APP_BASEURL_TYPE + "offeringType",
        custom_header: getCustomAuthHeader,
      },
      {
        name: "email_signature",
        endpoint:
          process.env.REACT_APP_BASEURL_EMAIL_SIGNATURE + "emailSignature",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "apple_VPASS",
        endpoint:
          process.env.REACT_APP_BASEURL_WALLET_APPLE + "emailSignature/appleWallet",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "roles",
        endpoint: process.env.REACT_APP_BASEURL_SIGNATURE + "roles",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "offices",
        endpoint: process.env.REACT_APP_BASEURL_SIGNATURE + "offices",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "levels",
        endpoint: process.env.REACT_APP_BASEURL_SIGNATURE + "levels",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "titles",
        endpoint: process.env.REACT_APP_BASEURL_SIGNATURE + "titles",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "industries",
        endpoint: process.env.REACT_APP_BASEURL_INDUSTRY + "industry",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "practices",
        endpoint: process.env.REACT_APP_BASEURL_SIGNATURE + "practices",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "practiceLevels",
        endpoint: process.env.REACT_APP_BASEURL_SIGNATURE + "practiceLevels",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "units",
        endpoint: process.env.REACT_APP_BASEURL_UNIT + "measureUnit",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "billingEntity",
        endpoint: process.env.REACT_APP_BASEURL_CUSTOMER + "billingEntity",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "customers",
        endpoint: process.env.REACT_APP_BASEURL_CUSTOMER + "customers",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "paymentTerms",
        endpoint: process.env.REACT_APP_BASEURL_CUSTOMER + "paymentTerms",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "industryManager",
        endpoint: process.env.REACT_APP_BASEURL_INDUSTRY + "industryManager",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "industry",
        endpoint: process.env.REACT_APP_BASEURL_INDUSTRY + "industry",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "projects",
        endpoint: process.env.REACT_APP_BASEURL_PROJECTS + "projects",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "projects_admin",
        endpoint: process.env.REACT_APP_BASEURL_PROJECTS + "projects/admin",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "project_status",
        endpoint: process.env.REACT_APP_BASEURL_PROJECTS + "projects/status",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "offer_request_logs",
        endpoint: process.env.REACT_APP_BASEURL_TYPE + "/offerRequestLogs",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "sales_levels",
        endpoint: process.env.REACT_APP_BASEURL_PROJECTS + "salesLevels",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "users",
        endpoint: process.env.REACT_APP_BASEURL_USER + "users",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "users/search",
        endpoint: process.env.REACT_APP_BASEURL_USER + "users/search",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "cutoffs",
        endpoint: process.env.REACT_APP_BASEURL_CUTOFF + "cutoffs",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "pm",
        endpoint: process.env.REACT_APP_BASEURL_CUTOFF + "pm",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "external_rates",
        endpoint: process.env.REACT_APP_BASEURL_EXTERNALRATES + "externalRates",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "standard_costs",
        endpoint: process.env.REACT_APP_BASEURL_EXTERNALRATES + "standardCosts",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "hierarchy",
        endpoint:
          process.env.REACT_APP_BASEURL_HIERARCHYMANAGEMENT + "hierarchy",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "internal_levels",
        endpoint:
          process.env.REACT_APP_BASEURL_EXTERNALRATES + "internalLevels",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "expense_reports",
        endpoint: process.env.REACT_APP_BASEURL_EXPENSES + "reports",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "expenses",
        endpoint: process.env.REACT_APP_BASEURL_EXPENSES + "expenses",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "acis",
        endpoint: process.env.REACT_APP_BASEURL_ACIS + "acis",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "pfes",
        endpoint: process.env.REACT_APP_BASEURL_PROJECT_PLANNING + "pfes",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "budgets",
        endpoint: process.env.REACT_APP_BASEURL_PROJECT_PLANNING + "budgets",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "approverTable",
        endpoint:
          process.env.REACT_APP_BASEURL_PROJECT_PLANNING + "approverTable",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        //TODO offices already exists for email signature app, find solution to unify
        name: "expense_offices",
        endpoint: process.env.REACT_APP_BASEURL_OFFICE + "offices",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "tr_hours",
        region: process.env.REACT_APP_BASE_REGION,
        endpoint: process.env.REACT_APP_BASEURL_TIMEREPORT + "hours",
        custom_header: getCustomAuthHeader,
      },
      {
        name: "tr_holidays",
        region: process.env.REACT_APP_BASE_REGION,
        endpoint: process.env.REACT_APP_BASEURL_TIMEREPORT + "holidays",
        custom_header: getCustomAuthHeader,
      },
      {
        name: "tr_expenses",
        region: process.env.REACT_APP_BASE_REGION,
        endpoint: process.env.REACT_APP_BASEURL_TIMEREPORT + "expenses",
        custom_header: getCustomAuthHeader,
      },
      {
        name: "tr_cutoff_email",
        region: process.env.REACT_APP_BASE_REGION,
        endpoint:
          process.env.REACT_APP_BASEURL_CUTOFF + "/timereport/notification",
        custom_header: getCustomAuthHeader,
      },
      {
        name: "reports",
        endpoint: process.env.REACT_APP_BASEURL_REPORTS + "reports",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "permissions",
        endpoint:
          process.env.REACT_APP_BASEURL_PERMISSIONS + "frontEndPermission",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "milestones",
        endpoint: process.env.REACT_APP_BASEURL_MILESTONES + "milestones",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "status",
        endpoint: process.env.REACT_APP_BASEURL_MILESTONES + "status",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "approval_projects",
        endpoint: process.env.REACT_APP_BASEURL_APPROVAL + "approval/projects",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "approval_hours",
        endpoint: process.env.REACT_APP_BASEURL_APPROVAL + "hours",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "approve_hours",
        endpoint: process.env.REACT_APP_BASEURL_APPROVAL + "approval",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "approval_expenses",
        endpoint: process.env.REACT_APP_BASEURL_APPROVAL + "expenses",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "approve_expenses",
        endpoint: process.env.REACT_APP_BASEURL_APPROVAL + "approval",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "export_data",
        endpoint: process.env.REACT_APP_BASEURL_APPROVAL + "export",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "wip",
        endpoint: process.env.REACT_APP_BASEURL_PROJECT_PLANNING + "wip",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "hr_reports",
        endpoint: process.env.REACT_APP_BASEURL_HR_REPORTS + "reports",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "order_values",
        endpoint: process.env.REACT_APP_BASEURL_ORDER_VALUE + "orderValues",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "cvGenerator",
        endpoint: process.env.REACT_APP_BASEURL_CVGENERATOR,
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "rolesManagement",
        endpoint: process.env.REACT_APP_BASEURL_ROLESMANAGEMENT,
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "useApprovers",
        endpoint: process.env.REACT_APP_BASEURL_TYPE + "useApprovers",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "dailyProgress",
        endpoint: process.env.REACT_APP_BASEURL_APPROVAL + "dailyProgress",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "currencyExchangeRates",
        endpoint: process.env.REACT_APP_BASEURL_PROJECT_PLANNING + "currencies-exchange-rates",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "companies",
        endpoint: process.env.REACT_APP_BASEURL_PROJECT_PLANNING + "companies-next",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "hierarchyUnits",
        endpoint: process.env.REACT_APP_BASEURL_PROJECT_PLANNING + "hierarchy-units",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "internalLevels",
        endpoint: process.env.REACT_APP_BASEURL_PROJECT_PLANNING + "internal-levels",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "projectSalesLevels",
        endpoint: process.env.REACT_APP_BASEURL_PROJECT_PLANNING + "project-sales-levels",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "pfe",
        endpoint: process.env.REACT_APP_BASEURL_PROJECT_PLANNING + "pfes",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
      {
        name: "pfeNext",
        endpoint: process.env.REACT_APP_BASEURL_PROJECT_PLANNING + "pfes-next",
        region: process.env.REACT_APP_BASE_REGION,
        custom_header: getCustomAuthHeader,
      },
    ],
  },
  Storage: {
    region: process.env.REACT_APP_BASE_REGION,
    bucket: process.env.REACT_APP_EXPENSES_BUCKET,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL,
  },
});



export default amplifyConfig;
